import { Component } from 'react';
import _ from 'lodash'
import { withRouter } from 'next/router'
import { withTranslation } from 'themes/modules/I18n'
import { setStore } from 'themes/components/Store'
import Router from 'next/router';
import route, { addQuery } from 'themes/route'
import { connect } from 'react-redux'
import { notification } from 'antd';

class BaseComponent extends Component {
  //hàm tạo ra HOC cho các component con
  static export({lang = ['common', 'pages', 'errors'], mapStateToProps, withAuth = false, withCookies = false, withPublicRuntimeConfig = false } = {}) {
    return (xComponent) => {
      if (mapStateToProps || withAuth || withCookies || withPublicRuntimeConfig) {
        const mapStateToPropsFnc = (state) => {
          let reduxObj = {}
          if (withAuth) reduxObj = { ...reduxObj, auth: state.auth}
          if (withCookies) reduxObj = { ...reduxObj, cookies: state.cookies}
          if (withPublicRuntimeConfig) reduxObj = { ...reduxObj, publicRuntimeConfig: state.publicRuntimeConfig}

          if (mapStateToProps) {
            reduxObj = {
              ...reduxObj,
              ...mapStateToProps(state, this.selector),
            }
          }
          return reduxObj
        }
        xComponent = connect(mapStateToPropsFnc)(xComponent)
      }
      xComponent = withTranslation(lang)(withRouter(xComponent))

      let staticFunctions = ["redirect", "getData"]
      staticFunctions.map(funcName => xComponent[funcName] = this[funcName])
      return xComponent
    }
  }

  //hàm bắt buộc của next, trả về các props
  static async getInitialProps() {
    return {
      namespacesRequired: ['common', 'pages','menu']
    }
  }

  constructor(props) {
    super(props)
    this.role = (props.auth || {}).role
    this.state = {
      loadingBtn: false,
      reload: 0
    }
    this.initQuery();
    
  }

  componentDidMount() {
    const { t, error } = this.props;
    if (error && t) {
      const { code, message } = error
      this.notify(t(`errors:${code}`), t(message), 'error')
    }
  }

  initQuery() {
    if (typeof window !== "undefined" && this.props.defaultQuery) {
      addQuery(this.props.defaultQuery)
    }
  }

  setStore = async (path, value) => {
    if (!this.props.dispatch) {
      console.error("Component is not connected to store.")
      return {}
    }
    
    return this.props.dispatch(setStore(path, value))
  }

  redirect(routeName, query, shallow = false) {
    let nextRoute;
    try {
      nextRoute = route(routeName, query)
    }
    catch (e) {
      nextRoute = {
        href: routeName,
        as: routeName
      }
    }

    Router.push(nextRoute.href, nextRoute.as, {
      shallow
    })
  }

  static redirect(routeName, query, shallow = false) {
    let nextRoute = route(routeName, query)
    Router.push(nextRoute.href, nextRoute.as, {
      shallow
    })
  }

  static getData(obj, path, defaultValue = undefined) {
    let value = _.get(obj, path, defaultValue)
    if (value == null) return defaultValue
    return value
  }
  static scrollTop() {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
  });
  }

  getData = BaseComponent.getData

  notify(message, description = '', type = "success") {
    notification[type]({
      message: message,
      description: description,
      duration: 4 //second
    });
  }

  switchLoadingBtn = () => {
    this.setState({
      loadingBtn: !this.state.loadingBtn
    })
  }

}

export default BaseComponent