const sidebar = [
  {
    title: "menu:Post Management",
    routeName: "frontend.admin.posts",
    icon: "",
    routeParams: {},
    type: 'group',
    children: [
      {
        title: "Post Categories",
        routeName: "frontend.admin.postCategories.index",
        icon: "folder",
        routeParams: {}
      },
      {
        title: "New Post",
        routeName: "frontend.admin.posts.create",
        icon: "file-add",
        routeParams: {}
      },
      {
        title: "Posts List",
        routeName: "frontend.admin.posts.index",
        icon: "database",
        routeParams: {}
      },
    ]
  },
  {
    title: "Product Management",
    routeName: "frontend.admin.products",
    icon: "",
    routeParams: {},
    type: 'group',
    children: [
      {
        title: "Product Categories",
        routeName: "frontend.admin.productCategories.index",
        icon: "folder",
        routeParams: {}
      },
      {
        title: "New Product",
        routeName: "frontend.admin.products.create",
        icon: "plus",
        routeParams: {}
      },
      {
        title: "Products List",
        routeName: "frontend.admin.products.index",
        icon: "database",
        routeParams: {}
      },
    ]
  },

  
 
  {
    title: "System",
    routeName: "frontend.admin.system",
    icon: "",
    type: 'group',
    routeParams: {},
    children: [
      {
        title: "Banners List",
        routeName: "frontend.admin.banners.index",
        icon: "picture",
        routeParams: {}
      },
      {
        title: "AboutUsPage",
        routeName: "frontend.admin.settings.aboutUs",
        icon: "smile",
        routeParams: {},
      },
      {
        title: "Settings",
        routeName: "frontend.admin.settings.index",
        icon: "setting",
        routeParams: {},
      },
      {
        title: "Menu",
        routeName: "frontend.admin.menus.index",
        icon: "menu",
        routeParams: {},
      }
    ]
  },

  {
    title: "Contact Management",
    routeName: "frontend.admin.contacts.index",
    icon: "",
    type: 'group',
    routeParams: {},
    children: [
      {
        title: "Contacts",
        routeName: "frontend.admin.contacts.index",
        icon: "contacts",
        routeParams: {}
      }
    ]
  },
  {
    title: "User Management",
    routeName: "frontend.admin.users",
    icon: "",
    type: 'group',
    routeParams: {},
    children: [
      {
        title: "Roles",
        routeName: "frontend.admin.groups.index",
        icon: "team",
        routeParams: {}
      },
      {
        title: "Users",
        routeName: "frontend.admin.users.index",
        icon: "user",
        routeParams: {}
      },
    ]
  }
]

export default sidebar